<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div>
        <div style="margin-bottom:10px">
          <v-btn color="primary" @click="goHome()">
            Ga terug
          </v-btn>
        </div>

        <v-row style="margin-top:40px">
          <v-col class="d-flex flex-column" sm="6" lg="6" md="4">
            <v-text-field
              v-model="form.oldPassword.value"
              solo
              label="Oud wachtwoord"
              prepend-inner-icon="mdi-lock-question"
            ></v-text-field>
            <v-text-field
              solo
              label="Nieuw wachtwoord"
              prepend-inner-icon="mdi-lock"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show ? 'text' : 'password'"
               @click:append="show = !show"
              :class="{
                'is-valid':
                  form.newPassword.value.length > 0 &&
                  has_minimum_length &&
                  has_number &&
                  has_lowercase &&
                  has_uppercase &&
                  has_special,
                'is-invalid':
                  form.newPassword.value.length > 0 &&
                  !(
                    has_minimum_length &&
                    has_number &&
                    has_lowercase &&
                    has_uppercase &&
                    has_special
                  ),
              }"
              v-model="form.newPassword.value"
            ></v-text-field>
            <template v-if="form.newPassword.value.length > 0">
              <div class="text-danger small" v-show="!has_minimum_length">
                - Het wachtwoord moet minstens 8 karakters lang zijn
              </div>
              <div class="text-danger small" v-show="!has_number">
                - Het wachtwoord moet minimaak 1 cijfer bevatten
              </div>
              <div class="text-danger small" v-show="!has_lowercase">
                - Het wachtwoord moet minimaal een kleinletter bevatten
              </div>
              <div class="text-danger small" v-show="!has_uppercase">
                - Het wachtwoord moet minimaal een hoofdletter bevatten
              </div>
              <div class="text-danger small" v-show="!has_special">
                - Het wachtwoord moet minimaal een van deze karakters bevatten
                !?@#$%^&*().;:_-
              </div>
            </template>

            <v-btn
              color="primary"
              :disabled="
                !(
                  has_minimum_length &&
                  has_number &&
                  has_lowercase &&
                  has_uppercase &&
                  has_special
                )
              "
            >
              wachtwoord wijzigen
            </v-btn>
          </v-col>

          <v-col
            class="d-flex flex-column text-center"
            sm="6"
            lg="6"
            md="4"
            style="background-color:#64b5f6;color:white"
          >
            <h5><b> Tip </b></h5>

            <p>
              Gebruik kleine letters, hoofdletters, cijfers, speciale tekens en
              spaties. Gebruik nooit voor de hand liggende woorden of reeksen,
              zoals de naam van je partner of kinderen, reeksen als 12345 of
              qwerty, welkom01. Verzin geen wachtwoord dat erg lijkt op één van
              jouw andere wachtwoorden.
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import firebase from 'firebase'
import api from "../api/apiUser"
export default {
  data() {
    return {
      show:false,
      CheckPassword: "",
      has_minimum_length: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      form: {
        newPassword: { value: "" },
        oldPassword: { value: "" },
      },
    };
  },
  mounted() {
         api.getKennisItems().then((data) => {
      this.items = data;
    });
   

  },

  watch: {
    "form.newPassword.value"() {
      this.has_minimum_length = this.form.newPassword.value.length > 8;
      this.has_number = /\d/.test(this.form.newPassword.value);
      this.has_lowercase = /[a-z]/.test(this.form.newPassword.value);
      this.has_uppercase = /[A-Z]/.test(this.form.newPassword.value);
      this.has_special = /[!?@#$%^&*().;:_-]/.test(this.form.newPassword.value);
    },
  },
  methods: {
    resetForm: function() {
      this.form.newPassword.value = "";
      this.form.oldPassword.value = "";
    },
   
    goHome() {
      this.$router.push("/KlantPortaal");
    },
    async reathenticateUser(oldPassword) {
      const user = firebase.auth().currentUser;
      const cred = firebase.auth.EmailAuthProvider.credential(
        user.email,
        oldPassword
      );
      return user.reauthenticateWithCredential(cred);
    },
    async changeCredentials() {
      this.reathenticateUser(this.form.oldPassword.value)
        .then(() => {
          const user = firebase.auth().currentUser;
          const newPassword = this.form.newPassword.value;
          user
            .updatePassword(newPassword)
            .then(() => {
              this.$swal.fire("Wachtwoord is gewijzigd!", "", "success");
              this.resetForm();
            })
            .catch((error) => {
              this.$swal.fire(error.message, "", "error");
            });
        })
        .catch((error) => {
          this.$swal.fire(error.message, "", "error");
        });
    },
  },
};
</script>
