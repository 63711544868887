<template>
  <UserSettings />
</template>

<script>
import UserSettings from "@/components/user/UserSettings";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    UserSettings,
  },
  metaInfo: {
    title: "Instellingen beheren | Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>