<template>
  <v-container>
    <template >
      <v-card class="mx-auto" max-width="400" style="margin-top:70px">
        <v-toolbar color="blue" dark>
          <v-app-bar-nav-icon></v-app-bar-nav-icon>

          <v-toolbar-title>Instellingen</v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-list subheader three-line>
          <v-subheader> <h4>Gebruikers instellingen</h4></v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Updates</v-list-item-title>
              <v-list-item-subtitle
                >Wij zijn continu bezig met het updaten van onze applicatie.Jouw
                feedback kan ons helpen met het toevoegen van nieuwe
                functionaliteiten.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Wachtwoorden</v-list-item-title>
              <v-list-item-subtitle
                >Wij slaan geen Wachtwoorden op.</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list flat subheader three-line>
          <v-subheader>General</v-subheader>

          <v-list-item-group v-model="settings" multiple active-class="">
            <v-list-item>
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Notifications</v-list-item-title>
                  <v-list-item-subtitle
                    >Houd me op de hoogte van updates.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-list-item>
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Marketing</v-list-item-title>
                  <v-list-item-subtitle
                    >Ik wil benaderd worden voor feedback
                    vragen</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-list-item>

            <v-list-item>
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Privacy</v-list-item-title>
                  <v-list-item-subtitle
                    >Jullie mogen mijn gegevens inzien</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      settings: [],
    };
  },
};
</script>
